<div class="section-title mt-5">
    <h2>Our Training Methodology</h2>
    <div class="bar"></div>
</div>
<ul class="ul_list">
    <li class="single-box mb-1">Delivered by Senior Working Professionals</li>
    <li class="single-box mb-1">Practical learning sessions followed by Lab practice</li>
    <li class="single-box mb-1">Topic wise assignments</li>
    <li class="single-box mb-1">Weekly Assessments</li>
    <li class="single-box mb-1">Code optimisation techniques</li>
    <li class="single-box">Curriculum Designed by Experts</li>
</ul>