import { Component } from '@angular/core';
import { DataService } from '../services/data.service';
// import { time } from 'console';

@Component({
  selector: 'app-online-training',
  templateUrl: './online-training.component.html',
  styleUrls: ['./online-training.component.scss']
})
export class OnlineTrainingComponent {

  onlineData: any[] = [];
  constructor(private dataService : DataService) { }
  ngOnInit(): void {
this.onlineRoomData();
  }

  onlineRoomData(){
    this.dataService.onlineData().subscribe((response) => {
      this.onlineData = response;
    });
  }
  // data = [
  //   { id: 1, date: "11/11/2024", time: "06:30 pm", courseName: 'Python Full Stack', action: '' , url:'https://us02web.zoom.us/j/7413185640?pwd=RGY1bXdFSmVBMlZNSkcydWNPVXJXUT09&omn=82178077617'},
  //   { id: 2, date: "11/11/2024", time: "07:30 pm", courseName: 'Oracle', action: '' , url:'https://us02web.zoom.us/j/3243524532?pwd=UU0wOHlLYUZpNnAyMXpuOTRtdDJQdz09&omn=86415537853'},
  //   { id: 3, date: "11/11/2024", time: "10:30 am", courseName: '.Net Full Stack', action: '' ,url:'https://us06web.zoom.us/j/7860445756?pwd=QlFtUi9IMzIydlRGQStFdXYzemd2dz09'},
  //   { id: 4, date: "11/11/2024", time: "06:30 pm", courseName: 'Python', action: '' , url:'https://us02web.zoom.us/j/7413185640?pwd=RGY1bXdFSmVBMlZNSkcydWNPVXJXUT09&omn=82178077617 '},
   
  // ];

  searchText = '';
  p = 1; // current page
  sortDirection = 1; // 1 for ascending, -1 for descending
  sortKey = ''; // key to sort by

  // Method to filter data based on search input
  get filteredData() {
    return this.onlineData.filter(item =>
      Object.values(item).some(val =>
        val.toString().toLowerCase().includes(this.searchText.toLowerCase())
      )
    );
  }

  // Method to sort by specific key
  sortBy(key: string) {
    this.sortKey = key;
    this.sortDirection = this.sortDirection * -1;
  }

}
