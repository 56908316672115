<!-- Start Page Title Area -->
<section class="page-title-banner1 pge-title">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h2>SSSIT Achievers</h2>
        <p>Explore new and trending free online coursessss.</p>
      </div>
    </div>
  </div>

  <div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
  <div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
  <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
  <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
  <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
  <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div>

</section>
<!-- End Page Title Area -->
<section class="ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-3 col-md-3 card-col" *ngFor="let item of testimonialData">
        <div class="card">
          <img [src]="item.imgUrl" alt="" class="card-img-top" />
          
          <!-- <div class="card-body">
            <h5 class="card-title">{{item.name}}</h5>
            <p class="card-text">{{item.designation}}</p>
            <h5 class="card-description"><i class="icofont-ui-office"></i> {{item.company}}</h5>
          </div> -->
        </div>
      </div>
    </div>
   
  </div>
</section>

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
  <app-social-media-links></app-social-media-links>
</section>
<!-- End Platform Connect -->


