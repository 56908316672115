import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { IContactModel } from './contact-model';
import { HttpService } from '../services/http.service';
import { MetaService } from '../services/meta.service';
import { filter, pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactObj = {} as IContactModel;
  spinner = false;
  private emailUrl = "../assets/ContactForm.php";

  constructor(private router: Router, private _http: HttpService,
    private metaService: MetaService) {

  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        pluck('urlAfterRedirects'),
        tap((data: string) => this.metaService.updateMeta(data))
      ).subscribe();
  }

  submitContact(f: NgForm) {
    this.spinner = true;
    this.contactObj.pageUrl = this.router.url;

    let postVars = {
      Name: this.contactObj.fullName,
      Email: this.contactObj.email,
      Mobile: this.contactObj.mobile,
      Course: this.contactObj.course,
      Page: this.router.url
    };


    this._http.postEmail(this.emailUrl, postVars).subscribe({
      next: (data: any) => {
        alert("We received your request and get back to you soon!");
        f.resetForm();
        this.spinner = false;
      },
      error: (reason: any) => {
        alert("Something went wrong");
        f.resetForm();
      },
      complete: () => console.log("Completed")
    });
  }

}


