<!-- Start Page Title Area -->
<section class="page-title-banner1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Gallery</h2>
            </div>
        </div>
    </div>

    <!-- <div class="shape1"><img src="assets/images/shape1.webp" alt="img"></div>
    <div class="shape2"><img src="assets/images/shape2.webp" alt="img"></div>
    <div class="shape3"><img src="assets/images/shape3.webp" alt="img"></div>
    <div class="shape6"><img src="assets/images/shape6.webp" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/images/shape8.webp" alt="shape"></div>
    <div class="shape9"><img src="assets/images/shape9.webp" alt="shape"></div> -->

    <div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
    <div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
    <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
    <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
    <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div>


</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active bt_ch" id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="true">Our Reviews</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link bt_ch" id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-success" type="button" role="tab" aria-controls="pills-success"
                            aria-selected="true">Our Success Stories</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link bt_ch" id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected="false">Our Infra</button>
                    </li>
                    <!-- <li class="nav-item" role="presentation">
                        <button class="nav-link bt_ch" id="pills-contact-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                            aria-selected="false">Our Celebrations</button>
                    </li> -->
                    <li class="nav-item" role="presentation">
                        <button class="nav-link bt_ch" id="pills-drive-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-drive" type="button" role="tab" aria-controls="pills-drive"
                            aria-selected="false">Placement Drives</button>
                    </li>
                </ul>
                <div class="tab-content shadow p-3 mb-5 bg-body rounded" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-12 p-3" *ngFor="let item of galleryData">
                                <div class="card bg-light-subtle">
                                    <img [src]="item.imgUrl" class="card-img-top" alt="...">
                                    <div class="card-body">
                                        <div class="text-section">
                                            <h5 class="card-title fw-bold">{{item.name}}</h5>
                                            <p class="card-text">{{item.description}}</p>
                                            <ngb-rating [max]="5" [(rate)]="item.ranking" [readonly]="false"></ngb-rating>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-success" role="tabpanel" aria-labelledby="pills-success-tab">

                        <div class="row">
                            <div class="col-lg-3 col-md-6 p-3">
                                <!-- <img src="assets/img/web_development.webp" /> -->
                            </div>
                            <div class="col-lg-9 col-md-6 p-3 mt-5">
                                <h5 class="blue_clr">Coming Soon</h5>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                        <div class="row">
                            <div class="col-lg-3 col-md-6 p-3">
                                <!-- <img src="assets/img/web_development.webp" /> -->
                            </div>
                            <div class="col-lg-9 col-md-6 p-3 mt-5">
                                <h5 class="blue_clr">Coming Soon</h5>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-drive" role="tabpanel" aria-labelledby="pills-drive-tab">
                        <div class="row">
                            <div class="col-lg-12 col-md-6 p-3 mb-5">
                                <div class="accordion" id="accordionExample">

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingGenThree">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseGenThree" aria-expanded="true"
                                                aria-controls="collapseGenThree">
                                                Our Drives
                                            </button>
                                        </h2>
                                        <div id="collapseGenThree" class="accordion-collapse collapse"
                                            aria-labelledby="headingGenThree" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="row">
                                                    <div class="col-md-3 img_drive">
                                                        <a href="assets/images/placement Drives/dot_net_drive.jpg">
                                                            <img src="assets/images/placement Drives/dot_net_drive.jpg"
                                                                alt="dot_net_drive.jpg" class="img_width">
                                                        </a>
                                                    </div>
                                                    <div class="col-md-3 img_drive">
                                                        <a href="../../assets/images/placement Drives/data_science_drive.jpg">
                                                            <img src="assets/images/placement Drives/data_science_drive.jpg"
                                                                alt="data Science" >
                                                        </a>
                                                    </div>
                                                    <div class="col-md-3 img_drive">
                                                        <a href="../../assets/images/placement Drives/java_drive.jpg">
                                                            <img src="../../assets/images/placement Drives/java_drive.jpg"
                                                                alt="Java Drive" >
                                                        </a>
                                                       
                                                    </div>
                                                    <div class="col-md-3 img_drive">
                                                        <a href="../../assets/images/placement Drives/python_drive.jpg">
                                                            <img src="../../assets/images/placement Drives/python_drive.jpg"
                                                                alt="Python Drive" >
                                                        </a>
                                                    </div>

                                                </div>
                                                <div class="row row-adjust">
                                                    <div class="col-md-3 img_drive">
                                                        <a href="../../assets/images/placement Drives/dotnet_drive_2.jpg">
                                                            <img src="../../assets/images/placement Drives/dotnet_drive_2.jpg"
                                                                alt="dot net" >
                                                        </a>
                                                    </div>
                                                    <div class="col-md-3 img_drive">
                                                        <a href="../../assets/images/placement Drives/data_science_drive_2.jpg">
                                                            <img src="../../assets/images/placement Drives/data_science_drive_2.jpg"
                                                                alt="Data Science" >
                                                        </a>
                                                    </div>
                                                    <div class="col-md-3 img_drive">
                                                        <a href="../../assets/images/placement Drives/java_drive_2.jpg">
                                                            <img src="../../assets/images/placement Drives/java_drive_2.jpg"
                                                                alt="java" >
                                                        </a>
                                                    </div>
                                                    <div class="col-md-3 img_drive">
                                                        <a href="../../assets/images/placement Drives/python_drive_2.jpg">
                                                            <img src="../../assets/images/placement Drives/python_drive_2.jpg"
                                                                alt="SSSIt_Ganesh4" >
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="https://www.linkedin.com/in/SSSitsolutions/" class="box" target="_blank">
                    <img src="assets/images/SSS_It_LinkedIn_Logo.webp" alt="SSS_It_LinkedIn_Logo">
                    <h3>Linkedin</h3>
                    <span>linkedin Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="https://www.facebook.com/SSSitsolutions" class="box" target="_blank">
                    <img src="assets/images/SSS_It_Facebook_Logo.webp" alt="SSS_It_Facebook_Logo">
                    <h3>Facebook</h3>
                    <span>Facebook Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="https://twitter.com/SSS_it" class="box" target="_blank">
                    <img src="assets/images/SSS_It_Twitter_Logo.webp" alt="SSS_It_Twitter_Logo">
                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="https://www.instagram.com/SSS_it/" class="box" target="_blank">
                    <img src="assets/images/SSS_It_Instagram_Logo.webp" alt="SSS_It_Instagram_Logo">
                    <h3>Instagram</h3>
                    <span>Instagram Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->