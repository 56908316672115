import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './about-us/about-us.component'
import { ContactUsComponent } from './contact-us/contact-us.component'

import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { InternshipComponent } from './internship/internship.component';
import { WorkshopsComponent } from './workshops/workshops.component';
import { ItTrainingComponent } from './it-training/it-training.component';
import { FormsModule } from '@angular/forms';
import { ItDevelopmentComponent } from './it-development/it-development.component';
import { PlacementPreparationComponent } from './placement-preparation/placement-preparation.component';
import { PartnerComponent } from './partner/partner.component';
import { MobileValidatorDirective } from './directives/mobile-validator.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryComponent } from './gallery/gallery.component';
import { ClassroomTrainingComponent } from './classroom-training/classroom-training.component';
import { OnlineTrainingComponent } from './online-training/online-training.component';
import { NormalCoursesComponent } from './normal-courses/normal-courses.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TestimonialsComponent } from './testimonials/testimonials.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InternshipComponent,
    WorkshopsComponent,
    ItTrainingComponent,
    ItDevelopmentComponent,
    PlacementPreparationComponent,
    AboutUsComponent,
    ContactUsComponent,
    PartnerComponent,
    MobileValidatorDirective,
    GalleryComponent,
    ClassroomTrainingComponent,
    OnlineTrainingComponent,
    NormalCoursesComponent,
    TestimonialsComponent,

  ],
  imports: [
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ModalModule.forRoot()
   
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
