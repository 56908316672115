<div class="section-title mt-5">
    <h2>Who Can Join This Course?</h2>
    <div class="bar"></div>
</div>

<ul class="ul_list">
    <li class="single-box mb-1">If you are pursuing your academics.</li>
    <li class="single-box mb-1">If you are a graduate and looking for a internship.</li>
    <li class="single-box mb-1">If you are passionate to learn technology.</li>
    <li class="single-box mb-1">If you want to learn technology and complete your academic projects.</li>
    <li class="single-box mb-1">If you are a graduate and looking for a Job.</li>
    <li class="single-box mb-1">If you want to become expert with the technology.</li>
    <li class="single-box mb-1">If you are an employee and want to migrate to new technology.</li>
    <li class="single-box mb-1">If you are working in a non-technical field & want to move to Software job.</li>
    <li class="single-box mb-1">Qualification: B.E / B.Tech / M.E / M.Tech / Any Degree</li>

</ul>