<ol type="1">
    <li><b>HTML & CSS</b>
        <ul type="square">
            <li>Intro to HTML Syntax</li>
            <li>The HTML, head, title, & body tags</li>
            <li>Headings, paragraphs, & lists</li>
            <li>The strong & em tags</li>
            <li>The doctype</li>
            <li>The meta tag </li>
            <li>Formatting tags</li>
            <li>Anchor tag, hrefs & download</li>
            <li>Linking to pages within a website</li>
            <li>The break tag</li>
            <li>Creating Paragraphs</li>
            <li>Table & other tags in Tables</li>
            <li>The image tag, source & alt attribute </li>
            <li>span, div & iframe</li>
            <li>HTML Semantic Elements</li>
            <li>Editing HTML in the DevTools Elements panel</li>
            <li>Intro to Cascading Style Sheets (CSS)</li>
            <li>Inline, Internal & External CSS</li>
            <li>CSS Selectors</li>
            <li>CSS Specificity</li>
            <li>The font-size, font-family, color, & line-height properties</li>
            <li>Hexadecimal color codes</li>
            <li>Position Properties</li>
            <li>Display Properties</li>
            <li>Padding & Margin Properties</li>
            <li>Background & Color Properties</li>
            <li>CSS borders</li>
            <li>Creating Shadows in CSS</li>
            <li>Media quiries</li>
            <li>Enabling, disabling, & editing CSS in the DevTools</li>
            <li>Using DevTools to fine-tune your CSS</li>
            <li>Bootstrap </li>
        </ul>
    </li>
    <li><b>Javascript</b>
        <ul type="square">
            <li>Intro to Javascript</li>
            <li>ECMA Standard</li>
            <li>Different Javascript Engines</li>
            <li>Data Types & Operators</li>
            <li>Control Statements & Loops</li>
            <li>Displaying Pop up messages</li>
            <li>Functions in Javascript</li>
            <li>Arrow Functions</li>
            <li>Variable & Functions Hoisting</li>
            <li>let, var & const </li>
            <li>Objects in Javascript</li>
            <li>Different Types of Object creations</li>
            <li>Creating classes using function</li>
            <li>Prototype</li>
            <li>Shallow copy & Deep copy</li>
            <li>Arrays</li>
            <li>Array functions - map, filter, reduce.... </li>
            <li>Closures</li>
            <li>Event handling</li>
            <li>DOM Manipulation</li>
            <li>Data Validations</li>
            <li>Template literals</li>
            <li>Spread Operator & Rest Parameters</li>
            <li>Object & Array Destructuring</li>
            <li>call, apply & bind</li>
            <li>Call back functions</li>
            <li>Promises</li>
            <li>async and await</li>
            <li>setTimeout, setInterval</li>
            <li>Event looping</li>
            <li>Browser API - Fetch API</li>
            <li>Web Storages - Local, Session Storages & Cookies</li>
        </ul>
    </li>
    <li><b>Typescript</b>
        <ul type="square">
            <li>Intro to Typescript</li>
            <li>Diff. Between Javascript & Typescript</li>
            <li>Data Types & Variables</li>
            <li>Working with classes</li>
            <li>Inheritance</li>
            <li>Working with Interfaces</li>
            <li>Generics</li>
            <li>Modules & Namespace</li>
        </ul>
    </li>
    <li><b>Angular</b>
        <ul type="square">
            <li>Intro to Angular</li>
            <li>Intro to SPA - Single Page Application</li>
            <li>Angular application bootstrapping flow</li>
            <li>Data Bindings</li>
            <li>RouterModule and Routing </li>
            <li>Eager Loading and Lazy Loading</li>
            <li>Modules</li>
            <li>Component & Life Cycle</li>
            <li>Directives</li>
            <li>Services & DI</li>
            <li>Pipes</li>
            <li>Data Sharing between components</li>
            <li>Route Parameter & Query Parameters</li>
            <li>Auth Guards</li>
            <li>Interceptors</li>
            <li>HttpClientModule & HttpClient</li>
            <li>Route Resolvers</li>
            <li>Creating Reusable components </li>
            <li>Observabls, Subjects & Types of Subjects</li>
            <li>Rxjs Operators</li>
        </ul>
    </li>
</ol>