import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, pluck, tap } from 'rxjs/operators';
import { MetaService } from '../services/meta.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  galleryData:any[] = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    private dataservice :DataService
  ) {
  }
  starRating = 5;
  starRating4 = 4;
  starRating3 = 3;
  starRating2 = 2;
  starRating1 = 1;

  ngOnInit(): void {
    this.getallGallery();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        pluck('urlAfterRedirects'),
        tap((data: string) => this.metaService.updateMeta(data))
      ).subscribe();
  }

  getallGallery(){
    this.dataservice.getgallery().subscribe((response) => {
      this.galleryData = response;
      console.log(this.galleryData)
    })
}
}