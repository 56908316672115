<!-- Start Page Title Area -->
<section class="page-title-banner1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <h2>Class Room Training</h2>
            </div>
        </div>
    </div>

    <div class="shape1">
        <img src="assets/images/SSS_It_DesignShapes1.webp" alt="img" />
    </div>
    <div class="shape2">
        <img src="assets/images/SSS_It_DesignShapes2.webp" alt="img" />
    </div>
    <div class="shape3">
        <img src="assets/images/SSS_It_DesignShapes3.webp" alt="img" />
    </div>
    <div class="shape6">
        <img src="assets/images/SSS_It_DesignShapes6.webp" alt="img" />
    </div>
    <div class="shape8 rotateme">
        <img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape" />
    </div>
    <div class="shape9">
        <img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape" />
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->

<section class="about-area ptb-100 error_p">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h4 class="text-center">
                        <b>Class Room Training Program</b>
                    </h4>
                    <div class="bar"></div>
                    <p class="text-center">
                        At SSSIT , classroom training in software development
                        offers a structured, instructor-led experience designed
                        to equip students with in-demand programming skills.
                        Expert trainers guide learners through comprehensive
                        modules on programming languages, frameworks, and tools,
                        ensuring a strong theoretical and practical foundation.
                        The curriculum emphasizes hands-on practice with live
                        coding sessions, assignments, and real-world projects.
                        Interactive discussions and group activities foster
                        collaboration, while personalized feedback helps each
                        student improve. Training takes place in well-equipped
                        labs or virtual platforms, providing access to the
                        latest software resources. This approach ensures
                        students are prepared for real-world software
                        development challenges.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- End About Area -->

<section class="feedback-area bg-gray">
    <div class="container mt-4 emboss">
        <input
            type="text"
            class="form-control mb-3 search-input emboss1"
            placeholder="Search..."
            [(ngModel)]="searchText"
        />

        <!-- Table -->
        <table class="table table-bordered table-hover table-responsive">
            <thead>
                <tr class="table_row table-warning">
                    <th>S No</th>
                    <th (click)="sortBy('courseName')">Course Name</th>
                    <th (click)="sortBy('date')">Date</th>
                    <th (click)="sortBy('time')">Time</th>
                    <th (click)="sortBy('facultyName')">Faculty Name</th>
                    <!-- <th>Action</th> -->
                </tr>
            </thead>
            <tbody>
                <tr
                    class="table_row1 table-info"
                    *ngFor="
                        let item of filteredData
                            | paginate : { itemsPerPage: 10, currentPage: p };
                        let i = index
                    "
                >
                    <td>
                        <span *ngIf="p == 1">{{ i + 1 }}</span>
                        <span *ngIf="p > 1">{{ (p - 1) * 10 + (i + 1) }}</span>
                    </td>
                    <td>{{ item.courseName }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.time }}</td>
                    <td>{{ item.facultyName }}</td>
                    <!-- <td><button type="submit" class="btn btn-primary">Join</button></td> -->
                </tr>
            </tbody>
        </table>

        <!-- Pagination controls -->
        <pagination-controls
            (pageChange)="p = $event"
            class="d-flex justify-content-center"
        ></pagination-controls>
    </div>
</section>
<!-- Start Join Area -->
<section class="join-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="join-img">
                    <img
                        src="assets/images/SSS_It_Marketing4.webp"
                        alt="SSS_It_Marketing4"
                    />
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="join-content">
                    <ul class="work_ul">
                        <li>
                            <img
                                class="img_tick"
                                src="assets/images/SSS_It_TickMark.webp"
                            />&nbsp; Dot NET Full Stack
                        </li>
                        <li>
                            <img
                                class="img_tick"
                                src="assets/images/SSS_It_TickMark.webp"
                            />&nbsp; HTML, CSS, Javascript
                        </li>
                        <li>
                            <img
                                class="img_tick"
                                src="assets/images/SSS_It_TickMark.webp"
                            />&nbsp; Java Full Stack
                        </li>
                        <li>
                            <img
                                class="img_tick"
                                src="assets/images/SSS_It_TickMark.webp"
                            />&nbsp; Python Full Stack
                        </li>
                        <li>
                            <img
                                class="img_tick"
                                src="assets/images/SSS_It_TickMark.webp"
                            />&nbsp; Digital Marketing
                        </li>
                        <li>
                            <img
                                class="img_tick"
                                src="assets/images/SSS_It_TickMark.webp"
                            />&nbsp; Software Testing (Manual)
                        </li>
                        <li>
                            <img
                                class="img_tick"
                                src="assets/images/SSS_It_TickMark.webp"
                            />&nbsp; Software Testing (Automation)
                        </li>
                        <li>
                            <img
                                class="img_tick"
                                src="assets/images/SSS_It_TickMark.webp"
                            />&nbsp; HR Training
                        </li>
                    </ul>
                    <!-- <a routerLink="/contact-us" class="btn btn-primary">Join Now</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Join Area -->

<!-- Start Join Area -->
<section class="join-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-3 col-md-6 p-2 mb-5 bg-body rounded text-center">
                <div class="join-img">
                    <img
                        src="assets/images/SSS_It_Latest_Technologies.webp"
                        alt="SSS_It_Latest_Technologies"
                    /><br />
                    <h4 class="mt-3">Latest Technologies</h4>
                    <p>
                        With the view on emerging trends, we identify new
                        technologies that could impact and further contribute to
                        employee training. We offer training and workshops on
                        these trending technologies for academic institutions
                        and corporate houses.
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 p-2 mb-5 bg-body rounded text-center">
                <div class="join-img">
                    <img
                        src="assets/images/SSS_It_Certification.webp"
                        alt="SSS_It_Certification"
                    /><br />
                    <h4 class="mt-3">Certification</h4>
                    <p>
                        Reinforce core concepts and new skills with built-in
                        quiz questions and simulated certification exams. This
                        certification process helps to develop appropriate
                        skills and knowledge to fulfil the market need.
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 p-2 mb-5 bg-body rounded text-center">
                <div class="join-img">
                    <img
                        src="assets/images/SSS_It_Real-time-Exp.webp"
                        alt="SSS_It_Real-time-Exp"
                    /><br />
                    <h4 class="mt-3">Real-time Experience</h4>
                    <p>
                        Connect with industry experienced IT professionals in
                        our best suited learning programs. We offer technical
                        support to our students. Define your goals and stick to
                        a training plan with the help from our coaches.
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 p-2 mb-5 bg-body rounded text-center">
                <div class="join-img">
                    <img
                        src="assets/images/SSS_It_Best_Training.webp"
                        alt="SSS_It_Best_Training"
                    /><br />
                    <h4 class="mt-3">Best Training Platform</h4>
                    <p>
                        Boost your career with quality guaranteed SSSIT
                        training. Learning blended to perfection, mix of
                        self-paced, interactive and applied learning, better
                        experience and better results
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Join Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <app-social-media-links></app-social-media-links>
</section>
<!-- End Platform Connect -->
