<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <h2>Interact With Our Users On Every Platform</h2>
        </div>


        <div class="col-lg-3 col-md-6">
            <a href="https://www.linkedin.com/in/sssit-kphb-893718325/" class="box" target="_blank">
                <img src="assets/images/SSS_It_LinkedIn_Logo.webp" alt="SSS_It_LinkedIn_Logo">
                <h3>Linkedin</h3>
                <span>linkedin Feed</span>
                <i class="icofont-bubble-right"></i>
            </a>
        </div>

        <div class="col-lg-3 col-md-6">
            <a href="https://www.facebook.com/profile.php?id=100067699524421" class="box" target="_blank">
                <img src="assets/images/SSS_It_Facebook_Logo.webp" alt="SSS_It_Facebook_Logo">
                <h3>Facebook</h3>
                <span>Facebook Feed</span>
                <i class="icofont-bubble-right"></i>
            </a>
        </div>

        <div class="col-lg-3 col-md-6">
            <a href="https://x.com/RameshVana68976?t=l-A7jupGhdEm5xLY4DL44Q&s=09" class="box" target="_blank">
                <img src="assets/images/SSS_It_Twitter_Logo.webp" alt="SSS_It_Twitter_Logo" width="43px" height="43px">
                <h3>Twitter</h3>
                <span>Twitter Feed</span>
                <i class="icofont-bubble-right"></i>
            </a>
        </div>

        <div class="col-lg-3 col-md-6">
            <a href="https://www.instagram.com/sss_it_?igsh=MXMxbXp2MXhxNWdhNw==" class="box" target="_blank">
                <img src="assets/images/SSS_It_Instagram_Logo.webp" alt="SSS_It_Instagram_Logo">
                <h3>Instagram</h3>
                <span>Instagram Feed</span>
                <i class="icofont-bubble-right"></i>
            </a>
        </div>
    </div>
</div>