import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit{

  @Input() interval: number = 3000; // Default interval for the slider

  testimonialData:any[] = [];
   constructor(private service:DataService){

   }
  ngOnInit(): void {
    this.testimonialsData();
  }

   testimonialsData(){
    this.service.getTestimonials().subscribe((response) => {
      this.testimonialData = response;
      console.log(this.testimonialData);
      
    });
  }
}
