<!-- Start Page Title Area -->
<section class="page-title-banner1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <h2>Online Training</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
    <div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
    <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
    <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
    <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100 error_p">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h4 class="text-center"><b>Online Training Program</b></h4>
                    <div class="bar"></div>
                    <p class="text-center">At SSSIT Institution, online training in software development provides a
                        flexible, self-paced learning experience guided by expert instructors. The training is delivered
                        through a virtual platform, allowing students to access interactive lessons, video tutorials,
                        and hands-on coding exercises from anywhere. The curriculum covers key programming languages,
                        frameworks, and tools, ensuring comprehensive learning with real-world applications. Learners
                        benefit from live Q&A sessions, discussion forums, and one-on-one feedback to clarify doubts and
                        enhance understanding. Online labs and projects give students practical exposure, while
                        assessments track their progress. This method offers the convenience of learning at one's own
                        pace while maintaining the quality of instructor support and resources.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- End About Area -->
<section class="feedback-area bg-gray">
    <div class="container mt-4 emboss">

        <input type="text" class="form-control mb-3 search-input emboss1" placeholder="Search..."
            [(ngModel)]="searchText" />

        <!-- Table -->
        <table class="table table-bordered table-hover table-responsive">
            <thead>
                <tr class="table_row table-warning">
                    <th (click)="sortBy('id')">S No</th>
                    <th (click)="sortBy('courseName')"> Course Name</th>
                    <th (click)="sortBy('date')">Date</th>
                    <th (click)="sortBy('time')">Time</th>
                    <th (click)="sortBy('Action')">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table_row1 table-info"
                    *ngFor="let item of filteredData  | paginate : { itemsPerPage: 10, currentPage: p };
                        let i = index">
                    <td>
                        <span *ngIf="p == 1">{{ i + 1 }}</span>
                        <span *ngIf="p > 1">{{ (p - 1) * 10 + (i + 1) }}</span>
                    </td>
                    <!-- <td>{{ i+1 }}</td> -->
                    <td>{{ item.courseName }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.time }}</td>
                    <td>
                        <a class="btn btn-primary" href="{{item.url}}">join</a>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Pagination controls -->
        <pagination-controls (pageChange)="p = $event" class="d-flex justify-content-center"></pagination-controls>
    </div>

</section>
<!-- Start Join Area -->

<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Courses</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.</p> -->
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="card custom-card">
                <img src="../../assets/images//web.png " class="card-img-top" alt />
                <div class="card-body">
                    <h5 class="card-title">UI Full Stack Web Development with Angular</h5>
                    <p class="card-text">Web Development with Angular covers building dynamic web applications using
                        Angular for the front-end and Node.js for the back-end. The course includes key Angular
                        concepts, responsive design with Bootstrap. It equips learners to create full-stack, scalable
                        web apps.</p>
                    <div class="rating">
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rate-add"></i>
                        <span class="rating">4.5</span>
                        <span class="span-btn"><button type="submit" class="btn btn-primary">Enroll</button></span>
                    </div>
                </div>
            </div>

            <div class="card custom-card">
                <img src="../../assets/images/testing.png" class="card-img-top" alt />
                <div class="card-body">
                    <h5 class="card-title">Full Stack Software Testing</h5>
                    <p class="card-text">Full Stack Software Testing involves testing both front-end and back-end
                        components of a software application. It covers UI testing, API testing, database validation,
                        and performance testing to ensure the entire system works as expected. This approach ensures
                        comprehensive coverage across all layers of the application.</p>
                    <div class="rating">
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <span class="rating">5</span>
                        <span class="span-btn"><button type="submit" class="btn btn-primary">Enroll</button></span>

                    </div>
                </div>
            </div>
            <div class="card custom-card">
                <img src="../../assets/images/dot net.png " class="card-img-top" alt />
                <div class="card-body">
                    <h5 class="card-title">Full Stack .NET Core</h5>
                    <p class="card-text">Full Stack .NET Core encompasses the development of web applications using the
                        .NET Core framework for server-side logic and Angular or React for front-end development. Key
                        aspects include RESTful API creation, database management with Entity Framework, and responsive
                        design principles.</p>
                    <div class="rating">
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <span class="rating">5</span>
                        <span class="span-btn"><button type="submit" class="btn btn-primary">Enroll</button></span>
                    </div>
                </div>
            </div>
            <div class="card custom-card">
                <img src="../../assets/images/python.png" class="card-img-top" alt />
                <div class="card-body">
                    <h5 class="card-title">Full Stack Python</h5>
                    <p class="card-text">Full Stack Python encompasses the complete development of web applications
                        using Python for both front-end and back-end technologies. It includes frameworks like Flask and
                        Django for server-side development, HTML/CSS for client-side, and databases like PostgreSQL for
                        data management.</p>
                    <div class="rating">
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <span class="rating">5</span>
                        <span class="span-btn"><button type="submit" class="btn btn-primary">Enroll</button></span>
                    </div>
                </div>
            </div>
            <div class="card custom-card">
                <img src="../../assets/images/Java.png" class="card-img-top" alt />
                <div class="card-body">
                    <h5 class="card-title">Full Stack Java</h5>
                    <p class="card-text">Full Stack Java development encompasses both front-end and back-end
                        technologies, utilizing Java frameworks like Spring and Hibernate for server-side logic. It
                        includes HTML, CSS, and JavaScript for user interfaces, along with database management, RESTful
                        APIs, and cloud deployment practices.</p>
                    <div class="rating">
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rate-remove"></i>
                        <span class="rating">4.5</span>
                        <span class="span-btn"><button type="submit" class="btn btn-primary">Enroll</button></span>
                    </div>
                </div>
            </div>
            <div class="card custom-card">
                <img src="../../assets/images/data sci.png" class="card-img-top" alt />
                <div class="card-body">
                    <h5 class="card-title">Full Stack Data Science & AI</h5>
                    <p class="card-text">Full Stack Data Science & AI encompasses the entire process of data analysis,
                        from data collection and cleaning to model building and deployment. It integrates statistical
                        analysis, machine learning, and data visualization, enabling professionals to derive insights
                        and build intelligent applications effectively.</p>
                    <div class="rating">
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rating"></i>
                        <i class="icofont-ui-rate-add"></i>
                        <span class="rating">4.5</span>
                        <span class="span-btn"><button type="submit" class="btn btn-primary">Enroll</button></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff" />
    </svg>
</section>
<!-- Start End Area -->

<!-- Start Join Area -->
<section class="join-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-3 col-md-6 p-2 mb-5 bg-body rounded text-center">
                <div class="join-img">
                    <img src="assets/images/SSS_It_Latest_Technologies.webp" alt="SSS_It_Latest_Technologies"><br>
                    <h4 class="mt-3">Latest Technologies</h4>
                    <p>With the view on emerging trends, we identify new technologies that could impact and further
                        contribute to employee training. We offer training and workshops on these trending technologies
                        for academic institutions and corporate houses.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 p-2 mb-5 bg-body rounded text-center">
                <div class="join-img">
                    <img src="assets/images/SSS_It_Certification.webp" alt="SSS_It_Certification"><br>
                    <h4 class="mt-3"> Certification</h4>
                    <p>Reinforce core concepts and new skills with built-in quiz questions and simulated certification
                        exams. This certification process helps to develop appropriate skills and knowledge to fulfil
                        the market need.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 p-2 mb-5 bg-body rounded text-center">
                <div class="join-img">
                    <img src="assets/images/SSS_It_Real-time-Exp.webp" alt="SSS_It_Real-time-Exp"><br>
                    <h4 class="mt-3"> Real-time Experience</h4>
                    <p>Connect with industry experienced IT professionals in our best suited learning programs. We offer
                        technical support to our students. Define your goals and stick to a training plan with the help
                        from our coaches.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 p-2 mb-5 bg-body rounded text-center">
                <div class="join-img">
                    <img src="assets/images/SSS_It_Best_Training.webp" alt="SSS_It_Best_Training"><br>
                    <h4 class="mt-3">Best Training Platform</h4>
                    <p>Boost your career with quality guaranteed SSSIT training. Learning blended to perfection,
                        mix of self-paced, interactive and applied learning, better experience and better results</p>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Join Area -->


<section>

</section>


<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <app-social-media-links></app-social-media-links>
</section>
<!-- End Platform Connect -->