<ol type="1">
    <li><b>MS SQL Server</b>
        <ul type="square">
            <li>Intro to DBMS & RDBMS</li>
            <li>Introduction to SQL Server</li>
            <li>Intoduction to SQL</li>
            <li>Data Types in SQL</li>
            <li>Basic of SQL, Types of SQL Statements </li>
            <li>DDL - create, alter, drop, truncate</li>
            <li>DML - select, insert, update, delete</li>
            <li>TCL - commit, rollback</li>
            <li>DCL - grant, revoke</li>
            <li>Tables</li>
            <li>Constraints - NOT NULL, UNIQUE, PRIMARY KEY, FOREIGN KEY, CHECK, DEFAULT, INDEX </li>
            <li>Identity Column</li>
            <li>Data Integrity</li>
            <li>Clauses - selete, top, distinct, from, where, group by, having, order by</li>
            <li>Joins - inner join, outer join, self join & cross join</li>
            <li>Sub Queries - Sub queries, corelated subquries</li>
            <li>Views</li>
            <li>Functions - scalar, inline & multi valued tabular functions</li>
            <li>Stored Procedures</li>
            <li>Triggers - ddl, dml, log on triggers</li>
            <li>Indexes - clustered, non clustered & unique indexes </li>
        </ul>
    </li>
</ol>