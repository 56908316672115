<!-- Start Page Title Area -->
<section class="page-title-banner1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <h2>Contact Us</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
    <div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
    <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
    <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
    <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Contact Info Area -->
<section class="contact-info-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-email"></i>
                    </div>
                    <h3>Mail Here</h3>
                    <p><a href="mailto:admin@sssit.info">admin&#64;sssit.info</a></p>

                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-google-map"></i>
                    </div>
                    <h3>Visit Here</h3>
                    <p>SSSIT COMPUTER EDUCATION , MIG:224, <br> II Floor, KPHB Metro Station, <br>
                        Beside : R.S.Brother's, KPHB Main Road, Hyderabad-72, Telangana India</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-phone"></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:+91 9866144861">+91 9866144861</a></p>
                    <p><a href="tel:+91 7032703254">+91 7032703254</a></p>
                    <p><a href="tel:+91 7032703253">+91 7032703253</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Info Area -->

<section class="contact-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Location</h2>
            <div class="bar"></div>
        </div>
        <div class="">
            <iframe allowfullscreen="" frameborder="0" height="350"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.2908973497406!2d78.39862921487781!3d17.4936217880151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x63515661d0fd15b3!2sSSSIT%20Computer%20Education!5e0!3m2!1sen!2sin!4v1594280897585!5m2!1sen!2sin"
                style="border:0;" width="100%"></iframe>
        </div>
    </div>
</section>

<!-- Start Contact Area -->
<section class="contact-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Get In Touch With Us</h2>
            <div class="bar"></div>
            <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
        </div>

        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <img src="assets/images/SSS_It_Marketing.webp" alt="SSS_It_Marketing">
            </div>

            <div class="col-lg-6 col-md-12">
                <form name="contactForm" #contactForm="ngForm"
                    (ngSubmit)="contactForm.valid && submitContact(contactForm)">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="text" name="fullName" class="form-control" #fullName="ngModel"
                                    [(ngModel)]="contactObj.fullName" required placeholder="Name">
                                <small
                                    *ngIf="(fullName.invalid && contactForm.submitted) || (fullName.touched && fullName.invalid)">Full
                                    Name is required</small>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="email" name="email" #email="ngModel" class="form-control"
                                    [(ngModel)]="contactObj.email" required placeholder="Email">
                                <small
                                    *ngIf="(email.invalid && contactForm.submitted) || (email.touched && email.invalid)">
                                    Email is required</small>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" name="mobile" #mobile="ngModel" class="form-control"
                                    [(ngModel)]="contactObj.mobile" required appMobileValidator placeholder="Phone">
                                <small
                                    *ngIf="(mobile.invalid && contactForm.submitted) || (mobile.touched && mobile.invalid)">
                                    Mobile number is invalid</small>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" name="course" #course="ngModel" class="form-control"
                                    [(ngModel)]="contactObj.course" required  placeholder="Enter Course">
                                <small
                                    *ngIf="(course.invalid && contactForm.submitted) || (course.touched && course.invalid)">
                                    Mobile number is invalid</small>
                            </div>
                        </div>

                        <!-- <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="5"
                                    placeholder="Your Message"></textarea>
                            </div>
                        </div> -->

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">
                                <span *ngIf="spinner" class="spinner-border spinner-border-sm"></span>
                                Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->