import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  get(arg0: string) {
    throw new Error('Method not implemented.');
  }

  // url = "https://api.goodrichitsolutions.com/api/Home";
  constructor(private _http: HttpClient) { }

  postEmail(url: string, obj: any) {
    return this._http.post(url, obj);
  }
}
