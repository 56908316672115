<!-- Start Page Title Area -->
<section class="page-title-banner1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <h2>Internships</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
    <div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
    <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
    <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
    <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h4 class="text-center"><b>OUR INTERNSHIP COURSES</b></h4>
                    <div class="bar"></div>
                    <p class="text-center">Our internship program enhances the candidates in real time software
                        development by applying the technologies what they have learnt. It teaches the real time
                        approach of software development by following best practices. The program has been designed in a
                        way that it bridges the gap between the industry requirements and student potential.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <!-- <i class="icofont-pay"></i> -->
                        <img src="../../assets/images/SSS_IT_Dotnet_Logo.webp" />
                    </div>
                    <h3>.NET Full Stack</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                    <a class="blu_t" href="/contact-us">Know More <i class="pl-5 fa fa-angle-double-right"></i></a>
                </div>
            </div>



            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <!-- <i class="icofont-optic"></i> -->
                        <img src="assets/images/SSS_It_Java_Logo.webp" />
                    </div>
                    <h3>Java Full Stack</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                    <a class="blu_t" href="/contact-us">Know More <i class="pl-5 fa fa-angle-double-right"></i></a>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <!-- <i class="icofont-pie-chart"></i> -->
                        <img src="assets/images/SSS_It_Python_Logo.webp" />
                    </div>
                    <h3>Python Full Stack</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                    <a class="blu_t" href="/contact-us">Know More <i class="pl-5 fa fa-angle-double-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <!-- <i class="icofont-music-note"></i> -->
                        <img src="assets/images/SSS_It_HTML_Logo.webp" />
                    </div>
                    <h3>Web Development - Angular </h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                    <a class="blu_t" href="/contact-us">Know More <i class="pl-5 fa fa-angle-double-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <!-- <i class="icofont-deviantart"></i> -->
                        <img src="assets/images/SSS_It_Digital_Markting_Logo.webp" />
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                    <a class="blu_t" href="/contact-us">Know More <i class="pl-5 fa fa-angle-double-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <!-- <i class="icofont-chart-pie-alt"></i> -->
                        <img src="assets/images/SSS_It_Testing_Logo.webp" />
                    </div>
                    <h3>Software Testing </h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                    <a class="blu_t" href="/contact-us">Know More <i class="pl-5 fa fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <app-social-media-links></app-social-media-links>
</section>
<!-- End Platform Connect -->