<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a target="_blank" href="#"><img src="../../assets/images/SSS_It_TechMahindra.webp" alt="SSS_It_TechMahindra"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="../../assets/images/SSS_It_Wipro.webp" alt="SSS_It_Wipro"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="../../assets/images/SSS_It_Google.webp" alt="SSS_It_Google"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="../../assets/images/SSS_It_Infosys.webp" alt="SSS_It_Infosys"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="../../assets/images/SSS_It_Capgemini.webp" alt="SSS_It_Capgemini"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="../../assets/images/SSS_It_Virtusa.webp" alt="SSS_It_Virtusa"></a>
            </div>

            <div class="item">
                <a target="_blank" href="#"><img src="../../assets/images/SSS_IT_Deloitte.webp" alt="SSS_It_Deloitte"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->