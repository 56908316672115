<!-- Start Page Title Area -->
<section class="page-title-banner1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <h2>Individual Courses</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
    <div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
    <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
    <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
    <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<section class="feedback-area ptb bg-gray">
    <div class="container">
        <div class="section-title">
        </div>
        <div class="card-container">
            <div class="row">
                <div class="col-xs-12 col-sm-3 col-md-3" *ngFor="let course of courses">
                    <div class="card mb-4">
                        <div class="view overlay">
                            <img [src]="course.imageUrl" alt="{{ course.title }}" class="card-img-top" />
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">{{ course.title }}</h4>
                            <p class="card-text">{{ course.description }}</p>
                            <div style="display: flex; justify-content: space-between;">
                                <!-- <button class="btn btn-primary btn_c cl_s" (click)="onButtonClick()">{{ course.buttonText
                                    }}</button> -->
                                <p class="card-duration">Duration: {{ course.duration }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="analysis-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class=" styling-arae">
                    <h3>Talk To Us!</h3>

                    <form class="form_w" name="contactForm" #contactForm="ngForm"
            (ngSubmit)="contactForm.valid && submitContact(contactForm)">
            <div class="row">
                <div class="col-sm-12 col-lg-3 padding_0">
                    <input type="text" name="fullName" class="form-control form-control-sm cl_s"
                        #fullName="ngModel" [(ngModel)]="contactObj.fullName" required
                        placeholder="Full Name">
                    <small
                        *ngIf="(fullName.invalid && contactForm.submitted) || (fullName.touched && fullName.invalid)">Full
                        Name is required</small>
                </div>
                <div class="col-sm-12 col-lg-3 padding_0">
                    <input type="email" name="email" #email="ngModel"
                        class="form-control form-control-sm cl_s" [(ngModel)]="contactObj.email" required
                        placeholder="Email">
                    <small
                        *ngIf="(email.invalid && contactForm.submitted) || (email.touched && email.invalid)">
                        Email is required</small>
                </div>
                <div class="col-sm-12 col-lg-3 padding_0">
                    <input type="text" name="mobile" #mobile="ngModel"
                        class="form-control form-control-sm cl_s" [(ngModel)]="contactObj.mobile" required
                        appMobileValidator placeholder="Phone Number">
                    <small
                        *ngIf="(mobile.invalid && contactForm.submitted) || (mobile.touched && mobile.invalid)">
                        Mobile number is invalid</small>
                </div>
                <div class="col-sm-12 col-lg-3 padding_0">
                    <input type="text" name="course" #course="ngModel"
                        class="form-control form-control-sm cl_s" [(ngModel)]="contactObj.course" required
                         placeholder="Enter Course">
                    <small
                        *ngIf="(course.invalid && contactForm.submitted) || (course.touched && course.invalid)">
                        Course Details Required</small>
                </div>
            </div>
            <div class="row center_content">
                <div class="col-sm-12 col-lg-2 padding_0">
                    <div class="d-grid gap-2">
                        <button type="submit" class="btn btn-primary btn_c  cl_s">
                            <span *ngIf="spinner" class="spinner-border spinner-border-sm"></span>
                            Submit</button>
                    </div>
                </div>
            </div>
                
            
        </form>
                </div>
            </div>

           
        </div>
    </div>
</section>

