import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private dataUrl = "assets/classroom.json";
  private dataUrl_1 = "assets/online.json";
  private gallary_Url = "assets/gallery.json";
  private testimonial_Url = "assets/testimonials.json";

  constructor(private http: HttpClient) {}

  classData(): Observable<any[]> {
    return this.http.get<any[]>(this.dataUrl);
  }

  onlineData(): Observable<any[]> {
    return this.http.get<any[]>(this.dataUrl_1);
  }
 
  getgallery(): Observable<any[]> {
    return this.http.get<any[]>(this.gallary_Url);
  }
  getTestimonials(): Observable<any[]> {
    return this.http.get<any[]>(this.testimonial_Url);
  }

}
