<!-- Start Marketing Main Banner -->
<div class="main-banner marketing-home">
    <div class="container-fluid">
        <div class="row">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../assets/images/posters/wearehiring.png" class="d-block corousal_img"
                            alt="placement">
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/posters/wearehiring2.png" class="d-block corousal_img"
                            alt="placement">
                    </div>
                    <div class="carousel-item ">
                        <img src="../../assets/images/posters/2.png" class="d-block corousal_img" alt="placement">
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/posters/1.png" class="d-block corousal_img" mt-5 alt="fullstack">
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/posters/4.png" class="d-block corousal_img" alt="dotnet">
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/posters/3.png" class="d-block corousal_img" alt="python">
                    </div>
                    <!-- <div class="carousel-item">
                    <img src="../../assets/images/posters/1.png" class="d-block corousal_img"  alt="java">
                  </div> -->
                    <div class="carousel-item">
                        <img src="../../assets/images/posters/5.png" class="d-block corousal_img" alt="aws">
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/posters/6.png" class="d-block corousal_img" alt="testing">
                    </div>
                    <div class="carousel-item">
                        <img src="../../assets/images/posters/10.png" class="d-block corousal_img" alt="spring">
                    </div>

                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>

        <div class="row row_b apppartner">
            <app-partner></app-partner>
        </div>
    </div>
</div>


<!-- <div class="bg-gray shape-1"></div>
<div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
<div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
<div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
<div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
<div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
<div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div> -->

<!-- Bubble Animte -->
<div class="bubble-animate">
    <div class="circle small square1"></div>
    <div class="circle small square2"></div>
    <div class="circle small square3"></div>
    <div class="circle small square4"></div>
    <div class="circle small square5"></div>
    <div class="circle medium square1"></div>
    <div class="circle medium square2"></div>
    <div class="circle medium square3"></div>
    <div class="circle medium square4"></div>
    <div class="circle medium square5"></div>
    <div class="circle large square1"></div>
    <div class="circle large square2"></div>
    <div class="circle large square3"></div>
    <div class="circle large square4"></div>
</div>

<!-- End Marketing Main Banner -->



<!-- Start Features Area -->
<section class="features-area marketing-features ptb-100 reasons_sec mt-5">
    <div class="container">
        <div class="section-title mtb">

            <h2>Reasons to Choose Us</h2>
            <div class="bar"></div>

        </div>
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box">
                            <img src="assets/images/SSS_It_Payment-img1.webp" alt="SSS_It_Payment-img1">
                            <h3>Industry led training programs</h3>
                            <p class="just">Industry-led training programs are an excellent way to bridge the gap
                                between academic
                                learning and the practical skills required in the workforce.</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/images/SSS_It_Payment-img2.webp" alt="SSS_It_Payment-img2">
                            <h3>Mentor Support</h3>
                            <p class="just">Mentor support is a crucial component of any learning or professional
                                development
                                program. A mentor provides guidance, motivation, and expertise to help individuals
                                achieve their goals, navigate challenges, and build confidence. </p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box mt-50">
                            <img src="assets/images/SSS_It_Payment-img3.webp" alt="SSS_It_Payment-img3">
                            <h3>Positive Learning Environment</h3>
                            <p class="just">A positive learning environment is essential for fostering effective
                                education and
                                personal growth. It encompasses a setting where students feel safe, supported, and
                                motivated to learn.</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/images/SSS_It_Payment-img4.webp" alt="SSS_It_Payment-img4">
                            <h3>Web Research</h3>
                            <p class="just">Our staff continuously research in the web for latest updates in
                                technologies and make
                                the students to research in the web for their doubts and updates</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="features-holder-content">
                    <div class="section-title">
                        <h2>We are driven by our results.</h2>
                        <div class="bar"></div>
                        <p class="gis">Being driven by results reflects a strong commitment to achieving measurable
                            success and delivering impactful outcomes. This mindset is key to building credibility,
                            fostering trust, and maintaining focus on your goals. Since 25 years we are shaping the
                            careers of thousands of people.</p>
                    </div>
                    <p class="gis">Would you like help in crafting and growing in professional career of any graduate
                        from any stream.</p>
                    <a routerLink="/about-us" class="btn btn-primary">About Us</a>
                </div>
            </div>
        </div>
    </div>

    <div class="features-inner-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-gift"></i>
                            </div>
                            <h3>Our Training Methodology</h3>
                            <ul class="ul_list">
                                <li class="single-box mb-1">Delivered by Senior Working Professionals</li>
                                <li class="single-box mb-1">Practical learning sessions followed by Lab practice</li>
                                <li class="single-box mb-1">Topic wise assignments</li>
                                <li class="single-box mb-1">Weekly Assessments</li>
                                <li class="single-box mb-1">Code optimisation techniques</li>
                                <li class="single-box">Curriculum Designed by Experts</li>
                            </ul>
                        </div>


                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="../../assets//images/SSS_It_Feautures.png" alt="SSS_It_Feautures.png">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/images/SSS_It_DesignShapes7.webp" alt="SSS_It_DesignShapes7"></div>
    <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="SSS_It_DesignShapes3"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="SSS_It_DesignShapes6"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="SSS_It_DesignShapes8">
    </div>
    <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="SSS_It_DesignShapes9"></div>
    <div class="shape10"><img src="assets/images/SSS_It_DesignShapes10.webp" alt="SSS_It_DesignShapes10"></div>
    <div class="shape11 rotateme"><img src="assets/images/SSS_It_DesignShapes11.webp" alt="SSS_It_DesignShapes11"></div>
</section>
<!-- End Features Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">

            <h2>Our Trainings</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <a routerLink="/dotnet" class="">
                        <div class="icon">
                            <!-- <i class="icofont-pay"></i> -->
                            <img src="assets/images/SSS_IT_Dotnet_Logo.webp" />
                        </div>
                        <h3>.NET Full Stack</h3>
                        <!-- <p>Well at public scho cheeky bugger grub burke.</p> -->
                    </a>
                </div>
            </div>



            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <a routerLink="/java" class="">
                        <div class="icon">
                            <!-- <i class="icofont-optic"></i> -->
                            <img src="assets/images/SSS_It_Java_Logo.webp" />
                        </div>
                        <h3>Java Full Stack</h3>
                        <!-- <p>Well at public scho cheeky bugger grub burke.</p> -->
                    </a>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <a routerLink="/python" class="">
                        <div class="icon">
                            <!-- <i class="icofont-pie-chart"></i> -->
                            <img src="assets/images/SSS_It_Python_Logo.webp" />
                        </div>
                        <h3>Python Full Stack</h3>
                        <!-- <p>Well at public scho cheeky bugger grub burke.</p> -->
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <a routerLink="/webdevelopment-angular">
                        <div class="icon">
                            <!-- <i class="icofont-music-note"></i> -->
                            <img src="assets/images/SSS_It_HTML_Logo.webp" />
                        </div>

                        <h3>Web Development - Angular</h3>
                    </a>
                    <!-- <p>Well at public scho cheeky bugger grub burke.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <a routerLink="/digital-marketing">
                        <div class="icon">
                            <!-- <i class="icofont-deviantart"></i> -->
                            <img src="assets/images/SSS_It_Digital_Markting_Logo.webp" />
                        </div>
                        <h3>Digital Marketing</h3>
                    </a>
                    <!-- <p>Well at public scho cheeky bugger grub burke.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <a routerLink="/testing" class="">
                        <div class="icon">
                            <!-- <i class="icofont-chart-pie-alt"></i> -->
                            <img src="assets/images/SSS_It_Testing_Logo.webp" />
                        </div>
                        <h3>Software Testing </h3>
                        <!-- <p>Well at public scho cheeky bugger grub burke.</p> -->
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100 d-none">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/images/SSS_It_WorkShop.webp" alt="SSS_It_WorkShop">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>Social Media Marketing</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus
                        in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-share-alt"></i>
                    </div>
                    <h3>Email Marketing</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus
                        in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/images/SSS_It_Marketing-2.webp" alt="SSS_It_Marketing-2">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/images/SSS_It_Marketing.webp" alt="SSS_It_Marketing">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>Paid Advertising</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus
                        in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>We provide proffesional staff</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus
                        in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/images/SSS_It_Best_Training.webp" alt="SSS_It_Best_Training">
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/images/SSS_It_Best_Training_DesignShapes7.webp"
            alt="SSS_It_Best_Training_DesignShapes7"></div>
    <div class="shape3"><img src="assets/images/SSS_It_Best_Training_DesignShapes3.webp"
            alt="SSS_It_Best_Training_DesignShapes3"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/images/SSS_It_Best_Training_DesignShapes6.webp"
            alt="SSS_It_Best_Training_DesignShapes6"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_Best_Training_DesignShapes8.webp"
            alt="SSS_It_Best_Training_DesignShapes8">
    </div>
    <div class="shape9"><img src="assets/images/SSS_It_Best_Training_DesignShapes9.webp"
            alt="SSS_It_Best_Training_DesignShapes9"></div>
    <div class="shape10"><img src="assets/images/SSS_It_Best_Training_DesignShapes10.webp"
            alt="SSS_It_Best_Training_DesignShapes10"></div>
    <div class="shape11 rotateme"><img src="assets/images/SSS_It_Best_Training_DesignShapes11.webp"
            alt="SSS_It_Best_Training_DesignShapes11"></div>
</section>
<!-- End Overview Area -->

<!-- Start Join Area -->
<section class="join-area ptb-100 d-none">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="join-img">
                    <img src="assets/images/SSS_It_WorkShop.webp" alt="SSS_It_WorkShop">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="join-content">
                    <span>Start 30 Days Free Trial</span>
                    <h3>Overall 400k+ Our Clients! Please Get Start Now</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo.</p>
                    <a routerLink="/contact-us" class="btn btn-primary">Join Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Join Area -->

<!-- Start Analysis Area -->
<section class="analysis-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="analysis-form">
                    <h3>Talk To Us!</h3>

                    <form name="contactForm" #contactForm="ngForm"
                        (ngSubmit)="contactForm.valid && submitContact(contactForm)">
                        <div class="form-group">
                            <input type="text" name="fullName" class="form-control" #fullName="ngModel"
                                [(ngModel)]="contactObj.fullName" required placeholder="Name">
                            <small
                                *ngIf="(fullName.invalid && contactForm.submitted) || (fullName.touched && fullName.invalid)">Full
                                Name is required</small>
                        </div>

                        <div class="form-group">
                            <input type="email" name="email" #email="ngModel" class="form-control"
                                [(ngModel)]="contactObj.email" required placeholder="Email">
                            <small *ngIf="(email.invalid && contactForm.submitted) || (email.touched && email.invalid)">
                                Email is required</small>
                        </div>

                        <div class="form-group">
                            <input type="text" name="mobile" #mobile="ngModel" class="form-control"
                                [(ngModel)]="contactObj.mobile" required appMobileValidator placeholder="Phone">
                            <small
                                *ngIf="(mobile.invalid && contactForm.submitted) || (mobile.touched && mobile.invalid)">
                                Mobile number is invalid</small>
                        </div>

                        <div class="form-group">
                            <input type="text" name="course" #course="ngModel" class="form-control"
                                [(ngModel)]="contactObj.course" required placeholder="Enter Course Deatails">
                            <small
                                *ngIf="(course.invalid && contactForm.submitted) || (course.touched && course.invalid)">
                                Course Details Required</small>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input" name="agreeStatus1" type="checkbox" value=""
                                id="flexCheckChecked" [(ngModel)]="agreeStatus">
                            <label class="form-check-label" for="flexCheckChecked">
                                <p class="gis"> I agree to the Terms & Conditions of "SSSIT" and give
                                    acceptance for the
                                    "Team - SSSIT " to reach me over Call, SMS, WhatsApp, Email.</p>
                            </label>
                        </div>

                        <button type="submit" [disabled]="!agreeStatus" class="btn btn-primary">
                            <span *ngIf="spinner" class="spinner-border spinner-border-sm"></span>
                            Submit</button>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="analysis-text">
                    <img src="../../assets/images/SSS_It_RightStudent1.jpeg" class="" alt="SSS_It_New-Men">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Analysis Area -->



<!-- Start Pricing Area -->
<!-- <section class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Training Offerings</h2>
            <div class="bar"></div>
            <p><b> Our Trainings Always Match to your Requirement.</b></p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Basic</h3>
                        <div class="price-value">$ 59.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>If you are pursuing your academics.</li>
                        <li>If you want to learn technology and complete your academic projects.</li>
                        <li>If you are an employee and want to migrate to new technology.</li>
                        <li>B.E/B.Tech/M.E/M.Tech/Any Degree (Year of Pass does not matter).</li>
                        <li>Technical Training</li>
                        <li>Internship Guidance</li>
                        <li>Job Guidance</li>
                    </ul>

                    <a routerLink="/contact-us" class="btn btn-primary">Join Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Advanced</h3>
                        <div class="price-value">$ 79.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>If you are a graduate and looking for a internship.</li>
                        <li>If you are a graduate and looking for a Job.</li>
                        <li>If you are working in a non-technical field & want to move to Software job.</li>
                        <li>B.E/B.Tech/M.E/M.Tech/Any Degree (2020,2021,2022 & 2023 Pass out students).</li>
                        <li>Technical Training, Mentor support</li>
                        <li>Mock Interviews, Resume Building</li>
                        <li>Live Project, Job Assistance</li>
                    </ul>

                    <a routerLink="/contact-us" class="btn btn-primary">Join Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Professional</h3>
                        <div class="price-value">$ 99.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>If you are passionate to learn technology.</li>
                        <li>If you want to become expert with the technology.</li>
                        <li>If you want to start a career.</li>
                        <li>B.E/B.Tech/M.E/M.Tech/Any Degree (2020,2021,2022 & 2023 Pass out students).</li>
                        <li>Technical Training, 1:1 Mentor support</li>
                        <li>Live Project, Mock Interviews</li>
                        <li>Aptitude & Reasoning Sessions, Resume Building, 100% Job Guarantee</li>

                    </ul>

                    <a routerLink="/contact-us" class="btn btn-primary">Join Now</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Pricing Area -->

<!--  Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.</p> -->
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/Images_jpg/client1.jpg" alt="client">
                    <h3>Tulasi Konatham</h3>
                    <span>Web Developer</span>
                </div>
                <p class="">The best institute with best experienced faculty and with low fees in Hyderabad. they give
                    real time project experience even. They maintain good quality of education.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/Images_jpg/client2.jpg" alt="client">
                    <h3>Sharanya Peddi</h3>
                    <span>SEO Expert</span>
                </div>
                <p>iam happyto learn 'C' language at SSSIT. Convenient timings,limited learners for a
                    batch if u could ask me i would surely suggest SSSIT.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/Images_jpg/client3.jpg" alt="client">
                    <h3>Bheemavarapu Reddy</h3>
                    <span>Web Developer</span>
                </div>
                <p>This is a great institute for beginners and advanced students, these are faculties lot of patience,
                    after finishing the course i went to them 4 times for doubts and they have clarified my doubts
                    without feeling inconvenience and they said "come to us any times if you have any further doubts".
                </p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/Images_jpg/client4.jpg" alt="client">
                    <h3>Chaitanya Kumar</h3>
                    <span>Web Developer</span>
                </div>
                <p>SSSIT Is an extraordinary institute. i learnt soo much from this institute. i became expert in ms
                    office, java, c, c++ by taking training here
                    Director Satish garu Is expertised faculty since 17 years explained the subject elobaratedly</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff" />
    </svg>
</section>
<!-- End Feedback Area -->

<!-- Start Get Started Area -->
<section class="get-started ptb-100 bg-gray d-none">
    <div class="container">
        <div class="section-title">
            <h2>Get Started Free</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.</p>
        </div>

        <div class="get-started-form">
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Name">
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email">
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Password">
                </div>

                <button type="submit" class="btn btn-primary">Sign Up</button>
                <p>or use your <a href="#">Facebook Account</a></p>
            </form>
        </div>
    </div>
</section>
<!-- End Get Started Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <app-social-media-links></app-social-media-links>
</section>
<!-- End Platform Connect -->