import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { IContactModel } from '../contact-us/contact-model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { NgForm } from '@angular/forms';
import { MetaService } from '../services/meta.service';
import { filter, pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  contactObj = {} as IContactModel;
  agreeStatus = true;
  spinner = false;
  private emailUrl = "../assets/ContactForm.php";

  constructor(
    private _http: HttpService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService
  ) { }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        pluck('urlAfterRedirects'),
        tap((data: string) => this.metaService.updateMeta(data))
      ).subscribe();
  }

  submitContact(f: NgForm) {
    console.log("OK");
    this.spinner = true;

    let postVars = {
      Name: this.contactObj.fullName,
      Email: this.contactObj.email,
      Mobile: this.contactObj.mobile,
      Course: this.contactObj.course,
      Page: this._router.url
    };


    this._http.postEmail(this.emailUrl, postVars).subscribe({
      next: (data: any) => {
        alert("We received your request and get back to you soon!");
        f.resetForm();
        this.spinner = false;
      },
      error: (reason: any) => {
        alert("Something went wrong");
        f.resetForm();
      },
      complete: () => console.log("Completed")
    });
  }

}