<ng-template #autoShownModal>
    <div class="modal-header" style="width: 1024px !important;">
        <h4 class="modal-title pull-left">Join any of Following Course</h4>
        <!-- <h4 class="modal-title pull-left">Register here for Drive</h4> -->
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center">
        <img src="../assets/images/popup1.png" class="img-fluid" alt="Descriptive Alt Text" />
        <br />
        <div class="row">
            <div class="col-6 col-sm-6">
                <a href="https://tinyurl.com/ergdfter " target="_blank"><button type="button"
                        class="btn btn-success mt-2">
                        Intellect Informatics
                    </button>
                </a>
            </div>
            <div class="col-6 col-sm-6">
                <a href="https://tinyurl.com/hiuhiuhui " target="_blank"><button type="button"
                        class="btn btn-success mt-2">
                        FaceFirst
                    </button>
                </a>
            </div>
        </div>
        <div class="btn_class"></div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1 top_form">
            <form class="form_w" name="contactForm" #contactForm="ngForm"
                (ngSubmit)="contactForm.valid && submitContact(contactForm)">
                <div class="row">
                    <div class="col-sm-12 col-lg-3 padding_0">
                        <input type="text" name="fullName" class="form-control form-control-sm cl_s" #fullName="ngModel"
                            [(ngModel)]="contactObj.fullName" required placeholder="Full Name" />
                        <small *ngIf="
                                (fullName.invalid && contactForm.submitted) ||
                                (fullName.touched && fullName.invalid)
                            ">Full Name is required</small>
                    </div>
                    <div class="col-sm-12 col-lg-3 padding_0">
                        <input type="email" name="email" #email="ngModel" class="form-control form-control-sm cl_s"
                            [(ngModel)]="contactObj.email" required placeholder="Email" />
                        <small *ngIf="
                                (email.invalid && contactForm.submitted) ||
                                (email.touched && email.invalid)
                            ">
                            Email is required</small>
                    </div>
                    <div class="col-sm-12 col-lg-3 padding_0">
                        <input type="text" name="mobile" #mobile="ngModel" class="form-control form-control-sm cl_s"
                            [(ngModel)]="contactObj.mobile" required appMobileValidator placeholder="Phone Number" />
                        <small *ngIf="
                                (mobile.invalid && contactForm.submitted) ||
                                (mobile.touched && mobile.invalid)
                            ">
                            Mobile number is invalid</small>
                    </div>
                    <div class="col-sm-12 col-lg-3 padding_0">
                        <input type="text" name="course" #course="ngModel" class="form-control form-control-sm cl_s"
                            [(ngModel)]="contactObj.course" required placeholder="Course" />
                        <small *ngIf="
                                (course.invalid && contactForm.submitted) ||
                                (course.touched && course.invalid)
                            ">
                            Course Required</small>
                    </div>
                </div>
                <div class="row">
                    <div class="text-center mt-1">
                        <button type="submit" class="btn btn-primary">
                            <span *ngIf="spinner" class="spinner-border spinner-border-sm"></span>
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<!-- app modal popup end -->

<app-site-header *ngIf="!(location == '/coming-soon')"></app-site-header>
<router-outlet></router-outlet>
<app-site-footer *ngIf="!(location == '/coming-soon')"></app-site-footer>

<!-- <app-header *ngIf="!(location == '/coming-soon')"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon')"></app-footer> -->