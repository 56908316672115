<!-- Start Page Title Area -->
<section class="page-title-banner1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>About Us</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
    <div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
    <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
    <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
    <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div>
    

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h4>Our Story - Our Strength</h4>
                        <div class="bar"></div>
                        <p>SSSIT Solutions has been committed in providing the highest quality, need-based
                            training to its clients. We are a 100% Economic Empowerment Company, driven by our team’s
                            passion for education, training and development and 100% commitment to excellence.</p>
                    </div>
                </div>
                <div class="about-content">
                    <div class="section-title">
                        <h4>About - What We Believe</h4>
                        <div class="bar"></div>
                        <p>We believe that the rich learning process our programs offer can affect powerful and positive
                            transformation in individuals, in student communities, educational institutions and the
                            corporate houses.</p>
                        <p>Our courses are tailored to client’s requirements with a structure that is flexible and
                            designed
                            to match clients’ available time and budget. SSSIT  produces its own material
                            which can be competitive to the reputable materials in the open market. We are also able to
                            provide off- and on-site training.</p>
                        <p>We pursue the highest quality in all our training programs by ensuring that our material is
                            relevant and unit standard aligned; our facilitators are experts in their respective fields
                            and
                            that our approach to learning is both visionary and innovative.</p>
                        <p>We have an outstanding track record in delivering responsive high quality courses to all
                            sectors
                            of the corporate world which we are able to customize to the timeframes and budget.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/images/SSS_IT_AboutUs.webp" alt="SSS_It_AboutUs">
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="" class="box" target="_blank">
                    <img src="assets/images/SSS_It_LinkedIn_Logo.webp" alt="SSS_It_LinkedIn_Logo">
                    <h3>Linkedin</h3>
                    <span>linkedin Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="" class="box" target="_blank">
                    <img src="assets/images/SSS_It_Facebook_Logo.webp" alt="SSS_It_Facebook_Logo">
                    <h3>Facebook</h3>
                    <span>Facebook Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="" class="box" target="_blank">
                    <img src="assets/images/SSS_It_Twitter_Logo.webp" alt="SSS_It_Twitter_Logo">
                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="" class="box" target="_blank">
                    <img src="assets/images/SSS_It_Instagram_Logo.webp" alt="SSS_It_Instagram_Logo">
                    <h3>Instagram</h3>
                    <span>Instagram Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->