<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"
                        ><img
                            src="../../../assets/images/SSSIT_logo.jpg"
                            alt="SSSIT_logo"
                    /></a>
                    <p>
                        A Visionary with more than 25 years of Experience in the
                        IT Industry, Mr.V.Ramesh is at the helm of affairs at
                        SSSIT, Steering it towards Excellence. SSSIT Founded in
                        1999 and operating for over of 25 years SSSIT has worked
                        steadily to achieve excellence in Computer Education and
                        Training, Software Development, Corporative Training and
                        Consultancy.
                    </p>

                    <!-- <p> SSSIT COMPUTER EDUCATION , HNO:15-24-224/15/nr, KPHB Colony, Kukatpally Kukatpally. Medchal Telangana INDIA. <br>
                        <b>Mobile:+91 9866144861 , 7032703254 ,7032703253 <br>
                            Mail: admin@sssit.info</b>
                    </p> -->
                    <ul class="social-list">
                        <li>
                            <a
                                href="https://www.facebook.com/SSSITKPHB?mibextid=ZbWKwL"
                                target="_blank"
                                ><i class="icofont-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="" target="_blank"
                                ><i class="icofont-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="" target="_blank"
                                ><i class="icofont-youtube"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/in/sssit-kphb-893718325/"
                                target="_blank"
                                ><i class="icofont-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/sssitkphb?igsh=MXMxbXp2MXhxNWdhNw=="
                                target="_blank"
                                ><i class="icofont-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Quick Links</h3>
                    <ul class="list">
                        <li><a routerLink="/dotnet">DotNet Fullstack</a></li>
                        <li><a routerLink="/java">Java Fullstack</a></li>
                        <li><a routerLink="/python">Python Fullstack</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="list">
                        <li><a routerLink="/testing">Testing Tools</a></li>
                        <li>
                            <a routerLink="/digital-marketing"
                                >Digital Marketing</a
                            >
                        </li>
                        <li>
                            <a routerLink="/webdevelopment-angular"
                                >Web Development</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Reach Us</h3>
                    <p>
                        SSSIT COMPUTER EDUCATION , MIG:224, II Floor, KPHB Metro
                        Station, <br />
                        Beside : R.S.Brother's, KPHB Main Road, Hyderabad-72,
                        Telangana India <br />
                        <b
                            >Mobile: <br />
                            +91 9866144861, 7032703254, 7032703253 <br />
                            Mail: admin&#64;sssit.info</b
                        >
                    </p>

                    <!-- <ul class="list">
                        <li><a routerLink="/about-us" routerLinkActive="active">About</a></li>
                        <li><a routerLink="/contact-us" routerLinkActive="active">Contact</a></li>
                        <li><a routerLink="/placement-preparation" routerLinkActive="active">Placement Preparation</a>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>
                        &copy;2024 SSSIT Compter Education. All rights reserved
                    </p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->
