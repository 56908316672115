import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { IContactModel } from './contact-us/contact-model';
import { NgForm } from '@angular/forms';
import { HttpService } from './services/http.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('autoShownModal', { static: true }) autoShownModal?: TemplateRef<any>;
    modalRef?: BsModalRef;

    contactObj = {} as IContactModel;
    agreeStatus = true;
    spinner = false;
    private emailUrl = "../assets/ContactForm.php";
    location: any;
    routerSubscription: any;

    constructor(private router: Router, private _http: HttpService, private modalService: BsModalService) {
    }

    ngOnInit() {
        this.showModal();
        this.recallJsFuntions();
    }
    showModal(): void {
        if (this.autoShownModal) {
            this.modalRef = this.modalService.show(this.autoShownModal);
        }
    }

    hideModal(): void {
        this.modalRef?.hide();
    }



    submitContact(f: NgForm) {
        this.spinner = true;
        this.contactObj.pageUrl = this.router.url;

        let postVars = {
            Name: this.contactObj.fullName,
            Email: this.contactObj.email,
            Mobile: this.contactObj.mobile,
            Course: this.contactObj.course,
            Page: this.router.url
        };


        this._http.postEmail(this.emailUrl, postVars).subscribe({
            next: (data: any) => {
                alert("We received your request and get back to you soon!");
                f.resetForm();
                this.spinner = false;
            },
            error: (reason: any) => {
                alert("Something went wrong");
                f.resetForm();
            },
            complete: () => console.log("Completed")
        });
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader-area').fadeIn('fast');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.preloader-area').fadeOut('fast');
                this.location = this.router.url;
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
}
