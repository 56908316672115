import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { InternshipComponent } from './internship/internship.component';
import { WorkshopsComponent } from './workshops/workshops.component';
import { ItTrainingComponent } from './it-training/it-training.component';
import { ItDevelopmentComponent } from './it-development/it-development.component';
import { PlacementPreparationComponent } from './placement-preparation/placement-preparation.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ClassroomTrainingComponent } from './classroom-training/classroom-training.component';
import { OnlineTrainingComponent } from './online-training/online-training.component';
import { NormalCoursesComponent } from './normal-courses/normal-courses.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'internship', component: InternshipComponent },
  { path: 'workshops', component: WorkshopsComponent },
  { path: 'it-training', component: ItTrainingComponent },
  { path: 'classroom-training', component: ClassroomTrainingComponent },
  { path: 'online-training', component: OnlineTrainingComponent },
  { path: 'it-development', component: ItDevelopmentComponent },
  { path: 'placement-preparation', component: PlacementPreparationComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'courses', component: NormalCoursesComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'dotnet-fullstack-training', loadChildren: () => import("./dotnet/dotnet.module").then(x => x.DotnetModule) },
  { path: 'java-fullstack-training', loadChildren: () => import("./java/java.module").then(x => x.JavaModule) },
  { path: 'python-fullstack-training', loadChildren: () => import("./python/python.module").then(x => x.PythonModule) },
  { path: 'testing', loadChildren: () => import("./testing/testing.module").then(x => x.TestingModule) },
  { path: 'webdevelopment-angular', loadChildren: () => import("./webdevelopment-angular/webdevelopment-angular.module").then(x => x.WebdevelopmentAngularModule) },
  { path: 'digital-marketing', loadChildren: () => import("./digital-marketing/digital-marketing.module").then(x => x.DigitalMarketingModule) },

  // { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
