<!-- Start Page Title Area -->
<section class="page-title-banner1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <h2>Software Development</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/images/SSS_It_DesignShapes1.webp" alt="img"></div>
    <div class="shape2"><img src="assets/images/SSS_It_DesignShapes2.webp" alt="img"></div>
    <div class="shape3"><img src="assets/images/SSS_It_DesignShapes3.webp" alt="img"></div>
    <div class="shape6"><img src="assets/images/SSS_It_DesignShapes6.webp" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/images/SSS_It_DesignShapes8.webp" alt="shape"></div>
    <div class="shape9"><img src="assets/images/SSS_It_DesignShapes9.webp" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active bt_ch" id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="true">Web Designing</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link bt_ch" id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected="false">Web Applications Development</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link bt_ch" id="pills-contact-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                            aria-selected="false">Digital Marketing</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link bt_ch" id="pills-new-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-contact1" type="button" role="tab" aria-controls="pills-contact"
                            aria-selected="false">Content Writing</button>
                    </li>
                </ul>
                <div class="tab-content shadow p-3 mb-5 bg-body rounded" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 p-3">
                                <img src="assets/images/SSS_It_Web_Designing.webp" />
                            </div>
                            <div class="col-lg-9 col-md-6 p-3 mt-5">
                                <p>Thinking Beyond your immediate future & Looking Beyond Your Direct Opportunities!</p>
                                <p>We create websites that look great, but with a strategy in place to help convert more
                                    website visitors into customers and set you up for success.</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6 p-3 mt-5 text-center">
                                <h5 class="blue_clr"><b>WEBSITE DESIGNING</b></h5>
                                <p>Advanced web designing technologies earmarks you to contrive your company in a better
                                    way and will have better experience.</p>
                            </div>
                            <div class="col-lg-6 col-md-6 p-3 mt-5 text-center">
                                <h5 class="blue_clr"><b>RESPONSIVE WEBSITE</b></h5>
                                <ul class="work_ul">
                                    <li>* Mobile Friendly Design</li>
                                    <li>* Responsiveness is Future of web</li>
                                    <li>* Better User Experience</li>
                                    <li>* Higher Conversion Rates</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                        <div class="row">
                            <div class="col-lg-3 col-md-6 p-3">
                                <img src="assets/images/SSS_It_Web_Development.webp" />
                            </div>
                            <div class="col-lg-9 col-md-6 p-3 mt-5">
                                <h5 class="blue_clr">A Web application is an application that is stored on a remote
                                    server and is delivered over the Internet through a browser interface.</h5>
                                <p>We are capable of developing web applications for any domain by using any
                                    technologies like .Net, PHP, Java, Python...</p>
                                <p>We have developed lot of applications for various clients and even we have our own
                                    products using .Net, SQL Server and Angular for academic institutions, Gym,
                                    Construction Companies etc.</p>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

                        <div class="row">
                            <div class="col-lg-3 col-md-6 p-3">
                                <img src="assets/images/SSS_It_Digital_Markting.webp" />
                            </div>
                            <div class="col-lg-9 col-md-6 p-3 mt-5">
                                <h5 class="blue_clr">Digital Marketing - Internet Marketing</h5>
                                <p>We are capable of developing web applications for any domain by using any
                                    technologies like .Net, PHP, Java, Python...</p>
                                <p>We have developed lot of applications for various clients and even we have our own
                                    products using .Net, SQL Server and Angular for academic institutions, Gym,
                                    Construction Companies etc.</p>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact-tab">

                        <div class="row">
                            <div class="col-lg-3 col-md-6 p-3">
                                <img src="assets/images/SSS_It_Content_Writing.webp" />
                            </div>
                            <div class="col-lg-9 col-md-6 p-3 mt-5">
                                <h5 class="blue_clr">We Write Content for Websites</h5>
                                <p>We are specialized in writing most relevant content for various websites to target
                                    business specific audience to attract business.</p>
                                <p>Our content will have keywords aimed towards improving a website's SEO. We develop,
                                    write content as per the business concept. We Check for keywords or generate a
                                    keyword, and research limitations for the keywords.</p>
                                <p>We produce a content to entice and engage visitors so they continue browsing the
                                    current website for a long time. The longer a visitor stays on a particular site,
                                    the greater the likelihood they will eventually become clients or customers.</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <app-social-media-links></app-social-media-links>
</section>
<!-- End Platform Connect -->