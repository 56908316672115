import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-classroom-training',
  templateUrl: './classroom-training.component.html',
  styleUrls: ['./classroom-training.component.scss']
})
export class ClassroomTrainingComponent  implements OnInit{
  classData: any[] = [];
    constructor(private dataService: DataService){}

  ngOnInit(): void {
   this.classroomData();
   console.log(this.classData)
  }

  classroomData(){
    this.dataService.classData().subscribe((response) => {
      this.classData = response;
    });
  }
 
  // data = [
  //   { id: 1, date: "04/11/2024", time: "10:30 am", courseName: 'Python Full Stack', facultyName: 'Mr.Shashi Kumar' },
  //   { id: 2, date: "04/11/2024", time: "06:30 pm", courseName: 'Python Full Stack', facultyName: 'Mr.Shashi Kumar' },
  //   { id: 3, date: "04/11/2024", time: "06:30 pm", courseName: 'Data Science AI & ML', facultyName: 'Mr.Francis' },
  //   { id: 4, date: "04/11/2024", time: "04:30 pm", courseName: 'Java Full Stack', facultyName: 'Mr.Rammohan Rao' },
  //   { id: 5, date: "04/11/2024", time: "08:30 am", courseName: 'Oracle', facultyName: 'Mr.Rabindra Kumar' },
  //   { id: 6, date: "04/11/2024", time: "09:30 am", courseName: 'Adv.Java', facultyName: 'Mr.Rabindra Kumar' },
  //   { id: 7, date: "04/11/2024", time: "07:30 pm", courseName: 'Adv.Java', facultyName: 'Mr.Rabindra Kumar' },
  //   { id: 8, date: "04/11/2024", time: "06:30 pm", courseName: 'C & DS', facultyName: 'Mr.Konda Reddy' },
  //   { id: 9, date: "04/11/2024", time: "05:30 pm", courseName: 'Html & Css', facultyName: 'Mr.Konda Reddy' },
  //   { id: 10, date: "04/11/2024", time: "07:30 pm", courseName: 'React Js', facultyName: 'Mr.Konda Reddy' },
  //   { id: 11, date: "04/11/2024", time: "05:30 pm", courseName: 'UI Technologies', facultyName: 'Mr.Konda Reddy' },
  //   { id: 12, date: "04/11/2024", time: "10:30 am", courseName: '.Net Full Stack', facultyName: 'Mr.Sathesh' },
  //   { id: 13, date: "04/11/2024", time: "05:30 pm", courseName: '.Net Full Stack', facultyName: 'Mr.Sathesh' },
  //   { id: 14, date: "04/11/2024", time: "09:00 am", courseName: 'Tally Prime', facultyName: 'Mr.Nagesh Babu' },
  //   { id: 15, date: "04/11/2024", time: "05:30 pm", courseName: 'Tally Prime', facultyName: 'Mr.Nagesh Babu' },
  //   { id: 16, date: "04/11/2024", time: "10:30 am", courseName: 'MS Office', facultyName: 'Mr.Lokesh' },
  //   { id: 17, date: "04/11/2024", time: "06:00 pm", courseName: 'MS Office', facultyName: 'Mr.Lokesh' },
  // ];

  searchText = '';
  p = 1; // current page
  sortDirection = 1; // 1 for ascending, -1 for descending
  sortKey = ''; // key to sort by

  // Method to filter data based on search input
  get filteredData() {
    return this.classData.filter(item =>
      Object.values(item).some(val =>
        val.toString().toLowerCase().includes(this.searchText.toLowerCase())
      )
    );
  }

  // Method to sort by specific key
  sortBy(key: string) {
    this.sortKey = key;
    this.sortDirection = this.sortDirection * -1;
  }

}
