<!-- Start Sidebar Modal -->
<div class="sidebar-modal">
    <div
        class="modal right fade"
        id="myModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true"
                            ><i class="icofont-close"></i
                        ></span>
                    </button>
                    <h2 class="modal-title text-white" id="myModalLabel2">
                        <b>SSSIT Job Handle</b>
                    </h2>
                </div>

                <div class="modal-body">
                    <!-- <div class="sidebar-modal-widget">
                        <h3 class="title">Additional Links</h3>

                        <ul>
                            <li><a routerLink="/">Login</a></li>
                            <li><a routerLink="/">Register</a></li>
                            <li><a routerLink="/">FAQ</a></li>
                            <li><a routerLink="/">Logout</a></li>
                        </ul>
                    </div> -->

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Job Seekers Hub</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="icofont-telegram"></i>
                                IT Jobs Hyderabad
                                <span
                                    ><a
                                        href="https://t.me/itjobshyderabad9"
                                        target="_blank"
                                        >Join our Telegram Channel</a
                                    ></span
                                >
                            </li>
                            <li>
                                <i class="icofont-telegram"></i>
                                NON IT Jobs Hyderabad
                                <span
                                    ><a
                                        href="https://t.me/nonitjobshyderabad"
                                        target="_blank"
                                        >Join our Telegram Channel</a
                                    ></span
                                >
                            </li>
                            <li>
                                <i class="icofont-telegram"></i>
                                Hyderabad Jobs
                                <span
                                    ><a
                                        href="https://t.me/hyderabadjobs99"
                                        target="_blank"
                                        >Join our Telegram Channel</a
                                    ></span
                                >
                            </li>
                            <li>
                                <i class="icofont-telegram"></i>
                                Freshers Jobs Hyderabad
                                <span
                                    ><a
                                        href="https://t.me/freshersjobshyderabad9"
                                        target="_blank"
                                        >Join our Telegram Channel</a
                                    ></span
                                >
                            </li>
                            <li>
                                <i class="icofont-whatsapp"></i>
                                Hyderabad Jobs
                                <span
                                    ><a
                                        href="https://chat.whatsapp.com/KpmkNQo1QQW0a4SpvIzIcm"
                                        target="_blank"
                                        >Join our Whatsapp Group</a
                                    ></span
                                >
                            </li>
                            <li>
                                <i class="icofont-whatsapp"></i>
                                For any quiries chat with
                                <span
                                    ><a
                                        href="https://api.whatsapp.com/send?phone=917013814324&text="
                                        target="_blank"
                                        >Whatsapp Chat</a
                                    ></span
                                >
                            </li>
                            <li>
                                <i class="icofont-phone"></i>
                                Phone
                                <span>+91 7013814324, 9490124655</span>
                            </li>
                            <!-- <li>
                                <i class="icofont-phone"></i>
                                Phone
                                <span>+123 456 7890</span>
                            </li>
                            <li>
                                <i class="icofont-phone"></i>
                                Phone
                                <span>+123 456 7890</span>
                            </li> -->
                        </ul>
                    </div>

                    <!-- <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3> -->

                    <!-- <ul class="social-list">
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->
